var debounce = require('lodash/debounce');

/* FIXME: temporarily defining these constants here... where should these live? */
(function (appParam) {
    var app = appParam;
    app.constants = app.constants ? app.constants : {};

    app.constants.breakpoints = {
        XS: 0,
        SM: 320,
        MD: 576,
        LG: 1024,
        XL: 1440
    };
    window.app = app;
}(window.app || {}));

/**
 * Add basic controls for ADA compliance
 * @param {HTMLElement} mediaContainer - HTMLElement for video container
 * @param {HTMLElement} controlContainer - HTMLElement for video controls
 */
function S7VideoControls(mediaContainer, controlContainer) {
    var video = mediaContainer.querySelector('video');
    if ((video && !video.controls) || !controlContainer) return;

    // disables native video controls, display custom controls
    video.controls = false;
    // eslint-disable-next-line no-param-reassign
    controlContainer.style.display = 'block';
    video.muted = true;

    var isAudioControls = controlContainer.classList.contains('audioControls');
    var isVideoControls = controlContainer.classList.contains('videoControls');

    var muteButton;
    if (isAudioControls) {
        muteButton = controlContainer.querySelector('.s7MuteButton');
        if (muteButton) {
            muteButton.addEventListener('click', function () {
                if (!video.muted) {
                    muteButton.classList.remove('video-audio-unmute-button');
                    muteButton.classList.add('video-audio-mute-button');
                    muteButton.ariaLabel = 'Unmute';
                    video.muted = true;
                } else {
                    muteButton.classList.remove('video-audio-mute-button');
                    muteButton.classList.add('video-audio-unmute-button');
                    muteButton.ariaLabel = 'Mute';
                    video.muted = false;
                }
            });
        }
    }

    var playPauseButton;
    if (isVideoControls || isAudioControls) {
        playPauseButton = controlContainer.querySelector('.s7PlayPauseButton');
        if (playPauseButton) {
            playPauseButton.addEventListener('click', function () {
                if (video.paused || video.ended) {
                    playPauseButton.classList.remove('video-audio-play-button');
                    playPauseButton.classList.add('video-audio-pause-button');
                    video.play();
                    playPauseButton.ariaLabel = 'Pause';
                } else {
                    playPauseButton.classList.remove('video-audio-pause-button');
                    playPauseButton.classList.add('video-audio-play-button');
                    video.pause();
                    playPauseButton.ariaLabel = 'Play';
                }
            });
        }
    }
}

/**
 * Switch image src based on screen size.
 * Usage:
 *  The image must have a data-breakpoints attribute containing a JSON string of src URLs. See example for format:
 *  <img src="image_M" alt="" id="myImage"
 *      data-breakpoints='{"sm":{"src":"image_SM"},"md":{"src":"image_MD"},"lg":{"src":"image_LG"},"xl":{"src":"image_XL"}}' />
 *  <script>
 *      window.s7ImageSet(document.getElementById("myImage"));
 *  </script>
 *
 * @param  {element} imgElement The img element to be updated.
 */
window.s7ImageSet = function (imgElement) { // eslint-disable-line no-unused-vars
    var imgHandle = imgElement;

    var breakpointKeys = Object.keys(window.app.constants.breakpoints);
    var breakpoint = {};
    for (var k = 0; k < breakpointKeys.length; k++) {
        breakpoint[breakpointKeys[k]] = breakpointKeys[k];
    }

    if (!imgHandle) return;

    var bp = {}; // Must be defined at top.
    var device = null;

    try {
        bp = JSON.parse(imgHandle.getAttribute('data-breakpoints'));
    } catch (err) {
        window.console.error('bad JSON data');
        return;
    }

    // imgHandle.addEventListener('load', function() {
    //  this.width = this.naturalWidth;
    //  this.height = this.naturalHeight;
    // });

    /**
     * Calculate ratio of width to height
     *
     * @param {number} a - Number
     * @param {number} b - Number
     * @returns {number} - Number
     */
    function getRatio(a, b) {
        if (b !== 0) {
            return a / b;
        }
        return 0;
    }

    /**
     * qsToObj - Query String to Object. Converts a query string into a JSON object.
     *     This string:
     *     'param1=false&key2=2&key3&somethingElse=something%20else'
     *     Becomes this object:
     *     {
     *         'param1': 'false',
     *         'key2': '2',
     *         'key3': null,
     *         'somethingElse': 'something else'
     *     }
     * @param  {string} queryString - The string to be converted.
     * @return {Object} - The result.
     */
    function qsToObj(queryString) {
        var qs = queryString;
        var qsObj = {};
        if (qs && qs.length) {
            if (qs[0] === '?') { // remove the ? if it's there.
                qs = qs.substring(1);
            }

            var pairs = qs.split('&'); // split the query string into name/value pairs
            for (var i = 0; i < pairs.length; i++) {
                var pairParts = pairs[i].split('=');
                var key = pairParts[0];
                var val = pairParts[1];
                qsObj[key] = (val !== undefined && val !== null) ? decodeURIComponent(val) : null; // don't decode null values
            }
        }
        return qsObj;
    }

    /**
     * objToQs - Object to Query String. Converts a simple object into a name/value query string.
     *     This object:
     *     {
     *         'false': false,
     *         'key2': 2,
     *         'null': null,
     *         'empty': '',
     *         'aSpace': 'something else'
     *     }
     *     Becomes this string:
     *     'false=false&key2=2&null&empty=&aSpace=something%20else'
     * @param  {Object} qsObj The object to be converted.
     * @return {string}       The result.
     */
    function objToQs(qsObj) {
        var qs = Object.keys(qsObj).map(function (key) {
            var qval = qsObj[key];
            var keyValuePair = key;
            if (qval !== undefined && qval !== null) { // if qval is undefined or null just return the key.
                keyValuePair += '=' + encodeURIComponent(qval);
            }
            return keyValuePair;
        }).join('&');
        return qs;
    }

    /**
     * setQueryString - Adds to or updates the query string in the give URL
     *     Calling this:
     *     setQueryString("//google.com/search?q=puppies", {"q": "kittens", "tbm": "isch"})
     *     Returns this:
     *     "//google.com/search?q=kittens&tbm=isch"
     * @param {string} url    The URL having the query string to be modified.
     * @param {Object} params An oject containing the query string keys and values to be added or updated.
     * @returns {string} - URL
     */
    function setQueryString(url, params) {
        // get parts of the url
        var parts = url.split('?');
        var base = parts[0];
        var qs = parts.length > 1 ? parts[1] : '';

        // convert query string to an object
        var qsObject = qsToObj(qs);

        // add or update the new params to the existing query string object
        if (params && typeof params === 'object') {
            Object.keys(params).forEach(key => {
                qsObject[key] = params[key];
            });
        }

        // rebuild the query string
        qs = objToQs(qsObject);

        // reassemble the url
        return base + '?' + qs;
    }

    /**
     * Get the window width
     *
     * @returns {number} - Number
     */
    function getWindowWidth() {
        // TODO: add scrollbar width if there is a scrollbar on body
        return window.innerWidth;
    }

    /**
     * Get device size
     *
     * @param {number} windowWidthNumber - Number
     * @returns {number} - Number
     */
    function getDeviceSize(windowWidthNumber) {
        var windowWidth = windowWidthNumber;
        if (!windowWidth) {
            windowWidth = getWindowWidth();
        }
        if (windowWidth >= window.app.constants.breakpoints.XL) {
            return breakpoint.XL;
        }
        if (windowWidth >= window.app.constants.breakpoints.LG) {
            return breakpoint.LG;
        }
        if (windowWidth >= window.app.constants.breakpoints.MD) {
            return breakpoint.MD;
        }
        return breakpoint.SM;
    }

    /**
     * Get breakpoint width
     *
     * @param {string} size - String
     * @returns {number} - Number
     */
    function getNearestBPInData(size) {
        var sizeExists = function (testSize) {
            var sizeResult = false;
            if (bp[testSize] && bp[testSize].src.length) {
                sizeResult = testSize;
            }
            return sizeResult;
        };

        /* eslint-disable */

        // else, check for next biggest option. Please note, missing breaks are intentional.
        switch (size) {
            case 'XS':
                if (sizeExists('xs')) {
                    return breakpoint.XS;
                }
            case 'SM':
                if (sizeExists('sm')) {
                    return breakpoint.SM;
                }
            case 'MD':
                if (sizeExists('md')) {
                    return breakpoint.MD;
                }
            case 'LG':
                if (sizeExists('lg')) {
                    return breakpoint.LG;
                }
            case 'XL':
                if (sizeExists('xl')) {
                    return breakpoint.XL;
                }
        }

        // else, check for next smallest option
        switch (size) {
            case 'XL':
                if (sizeExists('xl')) {
                    return breakpoint.XL;
                }
            case 'LG':
                if (sizeExists('lg')) {
                    return breakpoint.LG;
                }
            case 'MD':
                if (sizeExists('md')) {
                    return breakpoint.MD;
                }
            case 'SM':
                if (sizeExists('sm')) {
                    return breakpoint.SM;
                }
            case 'XS':
                if (sizeExists('xs')) {
                    return breakpoint.XS;
                }
        }
        /* eslint-enable */

        // if all else fails, return what we started with.
        return size;
    }

    /**
     * Window Resize Event
     */
    function winResized() {
        // get window width
        var currentDeviceSize = getDeviceSize(getWindowWidth());
        if (currentDeviceSize === device) {
            return;
        }
        device = currentDeviceSize;
        device = getNearestBPInData(device);
        // console.log('new device size',device);

        var newSrc;
        var newWidth;
        var newHeight;

        // update img src, width, height
        switch (device) {
            case breakpoint.XL:
                newSrc = bp.xl.src;
                newWidth = bp.xl.width;
                newHeight = bp.xl.height;
                break;
            case breakpoint.LG:
                newSrc = bp.lg.src;
                newWidth = bp.lg.width;
                newHeight = bp.lg.height;
                break;
            case breakpoint.MD:
                newSrc = bp.md.src;
                newWidth = bp.md.width;
                newHeight = bp.md.height;
                break;
            case breakpoint.SM:
            default:
                var containerWidth = imgHandle.parentElement.offsetWidth;
                if (containerWidth > 0 && containerWidth < bp.sm.width) {
                    var scale = getRatio(containerWidth, bp.sm.width);
                    newWidth = containerWidth;
                    newHeight = Math.round(scale * bp.sm.height); // s7 doesn't like decimals.
                    // s7 needs the inverse of the scale? Whatevs.
                    newSrc = setQueryString(bp.sm.src, {
                        scl: (1 / scale),
                        wid: newWidth,
                        hei: newHeight
                    });
                } else {
                    newSrc = bp.sm.src;
                    newWidth = bp.sm.width;
                    newHeight = bp.sm.height;
                }
        }

        if (newSrc) {
            imgHandle.src = newSrc;
        }

        if (newWidth) {
            imgHandle.width = newWidth;
        }

        if (newHeight) {
            imgHandle.height = newHeight;
        }

        var bpChangeEvent = new CustomEvent('bpChange', { detail: device });
        window.dispatchEvent(bpChangeEvent);
    }

    // call winResize to set the initial device size and image src
    winResized();

    // listen for a change in the window width
    window.addEventListener('resize', winResized);
};

/**
 * Sets the source and aspect ratio of video being served from S7
 * there are two video sources, one for mobile and one for desktop.
 * if there is no mobile video specified, it should run the desktop
 * video in both situations
 *
 * @param {string} mobileVideoSourceIn - Name of selected mobile video file
 * @param {string} desktopVideoSourceIn - Name of selected tablet and larger video file
 * @param {string} uniqueid - unique id of the container
 */
window.s7VideoSourceSet = function (mobileVideoSourceIn, desktopVideoSourceIn, uniqueid) {
    var lastWidth = window.innerWidth;  // Initial device/window width
    var videoElement = document.getElementById(uniqueid + '-video');
    var posterElement = document.getElementById(uniqueid + '-poster');

    videoElement.addEventListener('play', function () {
        posterElement.style.display = 'none';
    });

    /**
     * jsupdateSize - Sets the video sources and checks for resixze events
     * so it can chage the source for mobile video if it is present.
     */
    function jsUpdateSize() {
        var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        var mobileVideoSource = mobileVideoSourceIn;
        var desktopVideoSource = desktopVideoSourceIn;

        if (width < window.app.constants.breakpoints.MD) {
            if (mobileVideoSource) {
                document.querySelector('#' + uniqueid + '-video source').src = 'https://columbia.scene7.com/is/content/ColumbiaSportswear2/' + mobileVideoSource;
            } else {
                document.querySelector('#' + uniqueid + '-video source').src = 'https://columbia.scene7.com/is/content/ColumbiaSportswear2/' + desktopVideoSource;
            }
        } else {
            document.querySelector('#' + uniqueid + '-video source').src = 'https://columbia.scene7.com/is/content/ColumbiaSportswear2/' + desktopVideoSource;
        }

        if (videoElement) {
            videoElement.load();
            videoElement.play();
        }
    }

    if (videoElement && videoElement.classList.contains('s7LazyVideo')) {
        jsUpdateSize();
    } else {
        window.addEventListener('load', jsUpdateSize);
    }

    /**
     * isWidthResized - prevents the jsUpdateSize() function from being fired
     * too many times as the screen is resized
     */
    var isWidthResized = debounce(function () {
        if (window.innerWidth !== lastWidth) {
            lastWidth = window.innerWidth;
            jsUpdateSize();
        }
    }, 400);

    window.addEventListener('resize', isWidthResized);
    var el = document.getElementById(uniqueid + '-videocontainer');
    // eslint-disable-next-line no-unused-vars
    var controls;
    var controlContainer = el && el.querySelector('.s7MediaControls');
    if (controlContainer) {
        controls = new S7VideoControls(el, controlContainer);
    }
};
